import React from 'react';
import { Badge } from '../Badge/Badge';
import { SHAPE, SIZE, THEME } from '../Badge/Badge.types';
import { XCircleIcon } from '../Icons/solid';

export function renderLabel<T>(data: T | null | undefined, fallback: (data: T) => string) {
  if (!data) return '';
  if (typeof data === 'object') return fallback(data);
  return data as string;
}

export function getKey<T>(data: T): string {
  // { data.id != null } checks for both null and undefined
  if (data && typeof data === 'object' && 'id' in data && data.id != null) {
    return String(data.id);
  }
  return '';
}

type CondensedMultipleValues = { condensed: true };
type ExpandedMultipleValuesProps<TValue> = {
  condensed?: false;
  limit?: number;
  onRemove?: (value: TValue) => void;
  onRemoveAll?: () => void;
} & (TValue extends string
  ? { displayValue?: (value: TValue) => string }
  : { displayValue: (value: TValue) => string });

type CommonMultipleValueProps<TValue> = {
  value: TValue[];
  placeholder?: string;
};

export type SelectMultipleValueProps<TValue> = CommonMultipleValueProps<TValue> &
  (CondensedMultipleValues | ExpandedMultipleValuesProps<TValue>);

export function SelectMultipleValue<TValue>(props: SelectMultipleValueProps<TValue>) {
  const { value, placeholder } = props;

  if (value.length === 0) return <span className="opacity-60">{placeholder}</span>;
  if (props.condensed) {
    return <Badge theme={THEME.BLUE} shape={SHAPE.BASIC} size={SIZE.EXTRA_SMALL} label={`${value.length}`} />;
  }
  const { limit, onRemove, onRemoveAll, displayValue = (label: string) => label } = props;
  const showRemove = Boolean(onRemove);
  const showRemoveAll = Boolean(onRemoveAll);
  const showClearAll = showRemoveAll && value.length > 1;
  const [firstChunk, secondChunk] = [value.slice(0, limit), value.slice(limit, value.length)];
  return (
    <div className="w-full flex gap-1">
      <div className="flex flex-1 flex-row gap-1 flex-wrap">
        {firstChunk?.map((data) => {
          const label = renderLabel(data, displayValue);
          return (
            <div key={getKey(data)} className="max-w-[200px]">
              <Badge
                theme={THEME.BLUE}
                shape={SHAPE.BASIC}
                size={SIZE.EXTRA_SMALL}
                label={label}
                truncate
                withRemoveButton={showRemove}
                onRemove={(event) => {
                  event.preventDefault();
                  onRemove?.(data);
                }}
              />
            </div>
          );
        })}
        {limit && secondChunk.length > 0 && (
          <Badge theme={THEME.BLUE} shape={SHAPE.BASIC} size={SIZE.EXTRA_SMALL} label={`+${secondChunk.length}`} />
        )}
      </div>
      {showClearAll && (
        <button
          aria-label="remove all"
          className="self-start flex flex-row items-center"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            onRemoveAll?.();
          }}
        >
          <XCircleIcon className="text-gray-400 w-5 h-5" />
        </button>
      )}
    </div>
  );
}
