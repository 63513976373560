import { useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import {
  AdjustmentsHorizontalIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  CpuChipIcon,
  LightBulbIcon,
  LockOpenIcon,
  MapPinIcon,
  NewspaperIcon,
  ShapeIssueTrackerIcon,
  ShapeProjectGalleryIcon,
  ShapeShiftReportIcon,
  ShapeWeeklyPlannerIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
} from '@shape-construction/arch-ui/src/Icons/outline';
import { ShapeChannelsIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useFeatureFlag } from '@shape-construction/feature-flags';
import { useProjectChannelsCount } from 'app/channels/queries/useProjectChannels';

export type SidebarItemType = {
  key: string;
  title: string | JSX.Element;
  image: JSX.Element;
  route: string;
  isActiveOn?: string;
  notifications?: number;
  disabled?: boolean;
  hidden?: boolean;
};

export const useOverviewItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const labels = useMessageGetter('navigation');
  const { value: isActivitiesInSidebarEnabled } = useFeatureFlag('activities-in-sidebar');
  const { value: isDataHealthDashboardEnabled } = useFeatureFlag('data-health-dashboard');
  const isDisabled = !currentProject || currentProject?.currentTeamMemberStatus !== 'joined';

  const notificationsCount = useProjectChannelsCount(currentProject?.id);

  return [
    {
      key: 'timeline',
      title: labels('timeline'),
      image: <NewspaperIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/timeline`,
      isActiveOn: '/projects/:projectId/timeline/*',
      disabled: isDisabled,
    },
    {
      key: 'issues',
      title: labels('issues'),
      image: <ShapeIssueTrackerIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/issues`,
      isActiveOn: '/projects/:projectId/issues/*',
      disabled: isDisabled,
    },
    {
      key: 'shift_reports',
      title: labels('shiftReports'),
      image: <ShapeShiftReportIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/shift-reports/drafts`,
      isActiveOn: '/projects/:projectId/shift-reports/*',
      disabled: isDisabled,
    },
    {
      key: 'activities',
      title: 'Activities',
      image: <WrenchScrewdriverIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/activities`,
      isActiveOn: '/projects/:projectId/activities/*',
      disabled: isDisabled,
      hidden: !isActivitiesInSidebarEnabled,
    },
    {
      key: 'weekly_planner',
      title: labels('weeklyPlanner'),
      image: <ShapeWeeklyPlannerIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/weekly-planner/plans`,
      isActiveOn: '/projects/:projectId/weekly-planner/*',
      disabled: isDisabled,
    },
    {
      key: 'dashboard',
      title: labels('dashboard'),
      image: <ChartBarSquareIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/dashboard`,
      isActiveOn: '/projects/:projectId/dashboard/*',
      disabled: isDisabled,
    },
    {
      key: 'data_health_dashboard',
      title: labels('dataHealthDashboard'),
      image: <ChartBarIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/data-health-dashboard`,
      isActiveOn: '/projects/:projectId/data-health-dashboard/*',
      disabled: isDisabled,
      hidden: !isDataHealthDashboardEnabled,
    },
    {
      key: 'gallery',
      title: labels('gallery'),
      image: <ShapeProjectGalleryIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/gallery`,
      isActiveOn: '/projects/:projectId/gallery/*',
      disabled: isDisabled,
    },
    {
      key: 'channels',
      title: labels('channels'),
      image: <ShapeChannelsIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/channels`,
      isActiveOn: '/projects/:projectId/channels/*',
      disabled: isDisabled,
      notifications: notificationsCount,
    },
  ].filter((s) => !s.hidden);
};

export const useAdminItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const items = [];
  const labels = useMessageGetter('admin.menu');

  if (currentProject?.availableActions?.manageLocations) {
    items.push({
      key: 'locations',
      title: labels('locations'),
      image: <MapPinIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject.id}/settings/locations`,
      isActiveOn: '/projects/:projectId/settings/locations/*',
    });
  }

  if (currentProject?.availableActions?.manageDisciplines) {
    items.push({
      key: 'disciplines',
      title: labels('disciplines'),
      image: <LightBulbIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject.id}/settings/disciplines`,
      isActiveOn: '/projects/:projectId/settings/disciplines/*',
    });
  }

  items.push(
    {
      key: 'teams',
      title: labels('teams'),
      image: <UserGroupIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/settings/teams`,
      isActiveOn: '/projects/:projectId/settings/teams/*',
    },
    {
      key: 'access_requests',
      title: labels('accessRequests'),
      image: <LockOpenIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/settings/access-requests`,
      isActiveOn: '/projects/:projectId/settings/access-requests/*',
    },
    {
      key: 'custom_fields',
      title: labels('customFields'),
      image: <CpuChipIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/settings/custom-fields`,
      isActiveOn: '/projects/:projectId/settings/custom-fields/*',
    },
    {
      key: 'resources',
      title: labels('resources'),
      image: <SquaresPlusIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/settings/resources`,
      isActiveOn: '/projects/:projectId/settings/resources/*',
    },
    {
      key: 'project',
      title: labels('projectSettings'),
      image: <AdjustmentsHorizontalIcon className="h-6 w-6 text-icon-neutral-subtle" />,
      route: `/projects/${currentProject?.id}/settings/project`,
      isActiveOn: '/projects/:projectId/settings/project/*',
    }
  );

  return items;
};

export const useSidebarItems = (currentProject?: ProjectSchema): SidebarItemType[] => {
  const overviewItems = useOverviewItems(currentProject);
  const adminItems = useAdminItems(currentProject);

  return [...overviewItems, ...adminItems];
};
