import { useEffect } from 'react';
import { FEATURE_FLAGS_CONFIG, type FlagName } from '../featureFlags.config';
import { useFeatureFlagContext } from './context';
import type { FlagConfig, FlagResult } from './types';
import { useFeatureFlagsByTarget } from './useFeatureFlagsByTarget';
import { pickTargetFeatureFlag } from './utils/flagValue';

export const useFeatureFlag = <
  Name extends FlagName,
  Config extends FlagConfig<any> = (typeof FEATURE_FLAGS_CONFIG)[Name],
>(
  name: Name,
  defaultValue?: Config['default']
): FlagResult<Config> => {
  const { teamId } = useFeatureFlagContext();
  const { flagsByTarget, refetch } = useFeatureFlagsByTarget();

  useEffect(() => {
    if (!flagsByTarget || !teamId) return;
    const config = FEATURE_FLAGS_CONFIG[name];
    // Refetch flags if it's not available for the current team
    if (config?.target === 'team' && !flagsByTarget?.teams?.[`${teamId}`]) {
      refetch();
    }
  }, [teamId, flagsByTarget, name, refetch]);

  return pickTargetFeatureFlag(name, flagsByTarget, { defaultValue, currentTeamId: teamId });
};
