import type {
  IssueViewGroupBySchema,
  IssueViewSchemaSortBy,
  IssueViewSchemaSortOrder,
  ProjectSchema,
} from '@shape-construction/api/model';
import { useStableCallback } from '@shape-construction/hooks';
import { parseDate } from '@shape-construction/utils/DateTime';
import {
  type PredefinedOptionKey,
  datePickerPredefinedOptionsMap,
} from 'app/components/Filters/DateSelect/DateSelectOptions';
import { useProjectIssueViews } from 'app/queries/issues/views/views';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo } from 'react';
import { type CurrentSorting, type SortingOption, defaultTabSorting } from '../../IssueList/IssueSortingOptions';
import type { TabOption } from '../../IssueList/issue-tab-options';
import { tabIds } from '../../types';
import { ISSUES_FILTERS_KEY, dateFilterToViewPropertyMapping, emptyFilters } from '../constants';
import type {
  FiltersStorageData,
  IssuesFilterFormValues,
  IssuesFilters,
  IssuesFiltersFormDateKeys,
} from '../types/types';
import { mapSavedViews } from './saved-views-helpers';
import { type IssueGroupKey, defaultTabGrouping } from './useGroupingOptions';

export const localFiltersAtom = atom(emptyFilters);

const storageFiltersAtom = atomWithStorage<FiltersStorageData>(ISSUES_FILTERS_KEY, {});

const updateStorageFiltersAtom = atom(
  null,
  (get, set, update: { projectId: ProjectSchema['id']; tabId: TabOption; filters: Partial<IssuesFilters> }) => {
    const currentStorageFiltersData = get(storageFiltersAtom);

    set(storageFiltersAtom, {
      ...currentStorageFiltersData,
      [update.projectId]: {
        ...currentStorageFiltersData[update.projectId],
        [update.tabId]: update.filters,
      },
    });
  }
);

export const removeIssuesFilters = () => {
  localStorage.removeItem(ISSUES_FILTERS_KEY);
};

export const toGroupItems = (groupBy?: IssueViewGroupBySchema): null | IssueGroupKey[] => {
  if (!groupBy) return null;
  return (Array.isArray(groupBy) ? groupBy : [groupBy]) as IssueGroupKey[];
};

const dateFiltersFromIssues = (dateObj: IssuesFilters['published_date']) => {
  if (!dateObj) return undefined;

  const relativeDate = dateObj?.relative_date as PredefinedOptionKey;
  if (!relativeDate) {
    return {
      date: undefined,
      end_date: undefined,
    };
  }

  const range =
    relativeDate === 'custom'
      ? datePickerPredefinedOptionsMap.custom.value(dateObj?.date, dateObj?.end_date)
      : datePickerPredefinedOptionsMap[relativeDate].value();

  return {
    date: range.from!,
    end_date: range.to!,
  };
};

const getFilterValueFromCurrentStorageOrDefaultFilters = (
  currentStorageTabFilters: IssuesFilters,
  defaultFilters: IssuesFilters,
  filterKey: keyof IssuesFilters
) => currentStorageTabFilters?.[filterKey] || defaultFilters?.[filterKey];

export const useInitialValues = (projectId: ProjectSchema['id'], tabId: TabOption) => {
  const { data: viewTabs } = useProjectIssueViews(projectId);

  const emptyView: IssuesFilters = useMemo(
    () => ({
      ...emptyFilters,
      group_by: defaultTabGrouping[tabId] as IssuesFilters['group_by'],
      ...((defaultTabSorting[tabId] ?? defaultTabSorting.all) as Pick<IssuesFilters, 'sort_order' | 'sort_by'>),
    }),
    [tabId]
  );

  const defaultFilters = useMemo(() => {
    // setting the filters for each view tab after retrieval.
    const viewTabFilters: Record<string, IssuesFilters> = mapSavedViews(viewTabs);
    // setting the empty filters for the predefined tabs.
    const preDefinedTabs: Record<string, IssuesFilters> = Object.values(tabIds).reduce(
      (acc, predefinedTabId) => ({
        ...acc,
        [predefinedTabId]: {
          ...emptyFilters,
          group_by: defaultTabGrouping[predefinedTabId],
          ...defaultTabSorting[predefinedTabId],
        },
      }),
      {}
    );

    return {
      ...preDefinedTabs,
      ...viewTabFilters,
    };
  }, [viewTabs]);

  const [currentStorageFilters] = useAtom(storageFiltersAtom);
  const currentStorageFiltersByTab = useMemo(
    () => currentStorageFilters[projectId] || {},
    [currentStorageFilters, projectId]
  );

  const initialValues: IssuesFilterFormValues = useMemo(() => {
    const filterKeys: (keyof IssuesFilters)[] = [
      'filter_state',
      'category',
      'sub_category',
      'discipline',
      'location',
      'impact',
      'critical',
      'user_issues',
      'responsible',
      'responsible_team',
      'visibility_status',
      'published_date',
      'due_date',
      'updated_date',
      'planned_closure_date',
      'closed_date',
      'group_by',
      'group_properties',
      'sort_by',
      'sort_order',
    ];
    return filterKeys.reduce(
      (arr, filterKey) => ({
        ...arr,
        [filterKey]: getFilterValueFromCurrentStorageOrDefaultFilters(
          currentStorageFiltersByTab[tabId],
          defaultFilters[tabId],
          filterKey
        ),
      }),
      {} as IssuesFilters
    );
  }, [tabId, currentStorageFiltersByTab, defaultFilters]);

  return {
    defaultFilters,
    emptyView,
    initialValues,
  };
};

const getDateParams = (dateKey: IssuesFiltersFormDateKeys, dateObject?: { date?: Date; end_date?: Date }) => {
  if (!dateObject) return {};
  if (!dateObject.date || !dateObject.end_date) return {};

  const viewProperty = dateFilterToViewPropertyMapping[dateKey];
  return {
    [viewProperty.start]: parseDate(dateObject.date as Date).format('YYYY-MM-DD'),
    [viewProperty.end]: parseDate(dateObject.end_date as Date).format('YYYY-MM-DD'),
  };
};

export const useIssuesFilters = (projectId: ProjectSchema['id'], tabId: TabOption) => {
  const { defaultFilters, initialValues, emptyView } = useInitialValues(projectId, tabId);

  const [localFilters, setLocalFilters] = useAtom(localFiltersAtom);

  useEffect(() => {
    setLocalFilters(initialValues);
  }, [initialValues, setLocalFilters]);

  const clearLocalFilters = () => {
    setLocalFilters({ ...emptyView });
  };

  const [, updateStorageFiltersData] = useAtom(updateStorageFiltersAtom);
  const updateStorageFilters = useCallback(() => {
    updateStorageFiltersData({ projectId, tabId, filters: localFilters });
  }, [projectId, tabId, localFilters, updateStorageFiltersData]);

  const resetStorageFilters = useCallback(() => {
    updateStorageFiltersData({ projectId, tabId, filters: initialValues });
  }, [initialValues, projectId, tabId, updateStorageFiltersData]);

  const resetDefaultFilters = () => {
    setLocalFilters(defaultFilters[tabId]);
  };

  const queryParamsFromLocalFilters = useMemo(() => {
    if (!localFilters) return {};

    const {
      published_date,
      due_date,
      updated_date,
      planned_closure_date,
      closed_date,
      group_by: _groupBy,
      group_properties: _groupProperties,
      sort_by: _sortBy,
      sort_order: _sortOrder,
      ...otherParams
    } = localFilters;
    const publishedDate = dateFiltersFromIssues(published_date);
    const dueDate = dateFiltersFromIssues(due_date);
    const updatedDate = dateFiltersFromIssues(updated_date);
    const plannedClosureDate = dateFiltersFromIssues(planned_closure_date);
    const closedDate = dateFiltersFromIssues(closed_date);
    return {
      ...otherParams,
      ...getDateParams('published_date', publishedDate),
      ...getDateParams('due_date', dueDate),
      ...getDateParams('updated_date', updatedDate),
      ...getDateParams('planned_closure_date', plannedClosureDate),
      ...getDateParams('closed_date', closedDate),
    };
  }, [localFilters]);

  const setGroupingItems = useStableCallback((groups: IssueGroupKey[]) =>
    setLocalFilters({ ...localFilters, group_by: groups as IssueViewGroupBySchema })
  );

  const currentGrouping = useMemo(
    () => ({
      groupingItems: toGroupItems(localFilters.group_by) ?? [],
      groupingProperties: localFilters.group_properties,
      setGroupingItems,
    }),
    [localFilters, setGroupingItems]
  );

  const setSortBy = useStableCallback((sort: SortingOption['sort_by']) =>
    setLocalFilters({ ...localFilters, sort_by: sort as IssueViewSchemaSortBy })
  );
  const setSortOrder = useStableCallback((order: IssueViewSchemaSortOrder) =>
    setLocalFilters({ ...localFilters, sort_order: order })
  );

  const currentSorting: CurrentSorting = useMemo(
    () => ({
      currentSortingOptions: {
        sort_by: localFilters?.sort_by ?? defaultFilters[tabId]?.sort_by,
        sort_order: localFilters?.sort_order ?? defaultFilters[tabId]?.sort_order,
      } as SortingOption,
      setSortBy,
      setSortOrder,
      reset: (_: any) =>
        setLocalFilters({
          ...localFilters,
          sort_by: defaultFilters[tabId]?.sort_by,
          sort_order: defaultFilters[tabId]?.sort_order,
        }),
    }),
    [tabId, localFilters, defaultFilters, setSortBy, setSortOrder, setLocalFilters]
  );

  const isEqualToInitialValues = useMemo(() => {
    return isEqual(localFilters, initialValues);
  }, [localFilters, initialValues]);

  return {
    localFilters,
    isEqualToInitialValues,
    queryParamsFromLocalFilters,
    currentGrouping,
    currentSorting,
    updateLocalFilters: setLocalFilters,
    clearLocalFilters,
    updateStorageFilters,
    resetStorageFilters,
    resetDefaultFilters,
  };
};
