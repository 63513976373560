import {
  getGetApiProjectsProjectIdTeamsQueryKey,
  getGetApiProjectsProjectIdTeamsQueryOptions,
  getGetApiProjectsProjectIdTeamsTeamIdQueryOptions,
  useDeleteApiProjectsProjectIdTeamsTeamId,
  useGetApiProjectsProjectIdTeamsTeamId,
  useGetApiTeamJoinTokensToken,
  usePatchApiProjectsProjectIdTeamsTeamId,
  usePostApiProjectsProjectIdTeams,
} from '@shape-construction/api/api';
import type { IssueGroupCountEntitySchemaIdentifier, ProjectSchema } from '@shape-construction/api/model';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getProjectTeamsQueryOptions = getGetApiProjectsProjectIdTeamsQueryOptions;

/**
 * @deprecated Use getProjectTeamQueryOptions instead
 */
export const useProjectTeam = useGetApiProjectsProjectIdTeamsTeamId;
export const getProjectTeamQueryOptions = getGetApiProjectsProjectIdTeamsTeamIdQueryOptions;

export const useTeamGetter = (projectId: ProjectSchema['id']) => {
  const { data } = useSuspenseQuery(getProjectTeamsQueryOptions(projectId));

  return useCallback((teamId: IssueGroupCountEntitySchemaIdentifier) => data?.find(({ id }) => id === teamId), [data]);
};

export const useTeamJoinToken = useGetApiTeamJoinTokensToken;

// mutations
export const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return usePostApiProjectsProjectIdTeams({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();

  return usePatchApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return useDeleteApiProjectsProjectIdTeamsTeamId({
    mutation: {
      onSettled: (_, __, variables) => {
        queryClient.invalidateQueries({
          queryKey: getGetApiProjectsProjectIdTeamsQueryKey(variables.projectId),
        });
      },
    },
  });
};
