import { MessageProvider, useMessage, useMessageGetter } from '@messageformat/react';
import type { ProjectSchema } from '@shape-construction/api/model';
import { Button, Divider, IconButton, Menu, Portal } from '@shape-construction/arch-ui';
import { PlusIcon } from '@shape-construction/arch-ui/src/Icons/outline';
import { ChevronDownIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import galleryMessages from 'app/components/Gallery/messages.en.yaml';
import { MAX_FILE_SIZE_IN_BYTES } from 'app/constants/FileUpload';
import { useFileUploadValidator } from 'app/hooks/useFileUploadValidator';
import { useProjectDocumentsUploadWorkflow } from 'app/hooks/useProjectDocumentsUploadWorkflow';
import { useProject } from 'app/queries/projects/projects';
import omit from 'lodash.omit';
import React, { useState } from 'react';
import { UploadPreview } from '../Gallery/UploadPreview';
import { imageAcceptTypes } from '../Gallery/constants';
import type { DocumentBody, DocumentPreview } from '../Gallery/types';
import { isFileImageType } from '../Gallery/utils/UtilsGallery';
import type { MediaPickerOptions } from '../MediaPicker/MediaPicker';
import { GallerySection } from './GallerySection';
import { IssueTrackerSection } from './IssueTrackerSection';
import { ShiftManagerSection } from './ShiftManagerSection';

export const QuickNewButtonTrigger = () => {
  const title = useMessage('quickNewButton.trigger.title');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));

  return isLargeScreen ? (
    <Button color="primary" variant="contained" size="md" data-cy="quick-new-button" aria-label={title}>
      {title}
      <ChevronDownIcon className="w-5 h-5" />
    </Button>
  ) : (
    <IconButton
      data-cy="quick-new-button"
      aria-label={title}
      icon={PlusIcon}
      size="xl"
      color="primary"
      variant="contained"
    />
  );
};

export type QuickNewButtonProps = {
  projectId: ProjectSchema['id'];
};

export const QuickNewButton = ({ projectId }: QuickNewButtonProps) => {
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { data: project } = useProject(projectId);
  const { uploadDocuments } = useProjectDocumentsUploadWorkflow(projectId);
  const [documentsPreview, setDocumentsPreview] = useState<DocumentPreview[]>([]);

  const canCreateIssue = Boolean(project?.availableActions?.createIssue);
  const canCreateShiftReport = Boolean(project?.availableActions?.createShiftReport);
  const canCreateShiftActivity = Boolean(project?.availableActions?.createWeeklyWorkPlan); // api is not returning the correct permission yet
  const canCreateWeeklyWorkPlan = Boolean(project?.availableActions?.createWeeklyWorkPlan);
  const canUploadDocument = Boolean(project?.availableActions?.uploadDocument);
  const showCreateIssueDivider = canCreateIssue && (canCreateShiftReport || canUploadDocument);
  const showCreateShiftReportDivider = (canCreateShiftReport || canCreateShiftActivity) && canUploadDocument;
  const errors = useMessageGetter('errors.fileUpload');
  // include WWP permissions when enabling the section in menu (on the right-hand side of the evaluation), e.g.:
  // const showCreateIssueDivider = canCreateIssue && (canCreateShiftReport || canCreateWeeklyWorkPlan || canUploadDocument);
  // const showCreateShiftReportDivider = canCreateShiftReport && (canCreateWeeklyWorkPlan || canUploadDocument);
  // const showCreateWeeklyWorkPlanDivider = canCreateWeeklyWorkPlan && canUploadDocument;

  const createPermissionsCount = [
    canCreateIssue,
    canCreateShiftReport,
    canCreateShiftActivity,
    canCreateWeeklyWorkPlan,
    canUploadDocument,
  ].filter(Boolean).length;

  const { validateFiles, handleValidationErrors } = useFileUploadValidator({
    maxSizeInBytes: MAX_FILE_SIZE_IN_BYTES,
    errorMessages: {
      fileSizeMin: (filename, min) => errors('fileSizeMin', { filename, min }),
      fileSizeMax: (filename, max) => errors('fileSizeMax', { filename, max }),
      fileTypeInvalid: (filename) => errors('fileTypeInvalid', { filename }),
    },
  });

  const onAddDocument = (files: File[]) => {
    const results = validateFiles(files);
    handleValidationErrors(results);

    const validFiles = results.filter(({ isValid }) => isValid).map(({ file }) => file);

    const preview: DocumentPreview[] = validFiles.map((file) => ({
      fileToUpload: file,
      type: isFileImageType(file) ? 'image' : 'file',
      preview: URL.createObjectURL(file),
    }));
    setDocumentsPreview((state) => [...state, ...preview]);
  };

  const onRemoveDocument = (index: number) => {
    const newPreview = [...documentsPreview];
    newPreview.splice(index, 1);

    setDocumentsPreview(newPreview);
  };

  const onUpload = (files: File[], body: DocumentBody) => {
    onCloseModal();
    return uploadDocuments(files, body);
  };

  const onCloseModal = () => {
    setDocumentsPreview([]);
  };

  const options: MediaPickerOptions = {
    gallery: {
      accept: imageAcceptTypes,
      enabled: !isLargeScreen,
      multiple: true,
      onSelectFiles: onAddDocument,
    },
    camera: {
      enabled: true,
      multiple: true,
      onSelectFiles: onAddDocument,
    },
    documents: {
      accept: '*',
      enabled: true,
      multiple: true,
      onSelectFiles: onAddDocument,
    },
  };

  if (!project || createPermissionsCount === 0) return null;

  return (
    <MessageProvider messages={galleryMessages}>
      <Menu.Root>
        <Menu.Trigger as="div" className="relative md:mr-5">
          <QuickNewButtonTrigger />
        </Menu.Trigger>
        <Portal>
          <Menu.Items
            className="md:w-96 whitespace-normal text-gray-700 flex-col justify-start items-start inline-flex"
            unmount={false}
          >
            <IssueTrackerSection projectId={projectId} />
            {showCreateIssueDivider && <Divider orientation="horizontal" />}
            <ShiftManagerSection projectId={projectId} />
            {showCreateShiftReportDivider && <Divider orientation="horizontal" />}
            {/* Section switched off until WWP is released */}
            {/* <WeeklyPlannerSection projectId={projectId} /> */}
            {/* {showCreateWeeklyWorkPlanDivider && <Divider orientation="horizontal" />} */}
            <GallerySection projectId={projectId} onUpload={onAddDocument} />
            {!isLargeScreen && <div className="h-4 w-full opacity-0" />}
          </Menu.Items>
        </Portal>
      </Menu.Root>

      {documentsPreview.length > 0 && (
        <UploadPreview
          projectId={projectId}
          documentsPreview={documentsPreview}
          mediaPickerOptions={omit(options, ['projectGallery'])}
          onUploadDocuments={onUpload}
          onClosePreview={onCloseModal}
          onRemoveDocument={onRemoveDocument}
        />
      )}
    </MessageProvider>
  );
};
