import { getGetApiFeatureFlagsQueryKey, getGetApiFeatureFlagsQueryOptions } from '@shape-construction/api/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useFeatureFlagContext } from './context';
import type { FeatureFlagsByTarget } from './types';
import { mapValues, toFeatureFlags } from './utils/flagValue';

export const featureFlagsQueryKey = (deviceId: string, userId?: string) => [
  ...getGetApiFeatureFlagsQueryKey({ device_session_id: deviceId }),
  userId,
];

export const useFeatureFlagsByTarget = () => {
  const { deviceId, userId } = useFeatureFlagContext();

  const { data: flagsByTarget, refetch } = useQuery(
    getGetApiFeatureFlagsQueryOptions(
      { device_session_id: deviceId },
      {
        query: {
          queryKey: featureFlagsQueryKey(deviceId, userId),
          refetchOnWindowFocus: process.env.NODE_ENV === 'development', // Refetch on focus only during development
          refetchOnMount: false,
          refetchOnReconnect: true,
          placeholderData: keepPreviousData,
          staleTime: process.env.NODE_ENV === 'development' ? 5 * 1000 : 5 * 60 * 1000, // 5 seconds on development, 5 minutes otherwise
          refetchInterval: 10 * 60 * 1000, // 10 minutes

          select: (featureFlagsData): FeatureFlagsByTarget => ({
            user: featureFlagsData.user && toFeatureFlags(featureFlagsData.user),
            session: featureFlagsData.session && toFeatureFlags(featureFlagsData.session),
            teams: featureFlagsData.teams && mapValues(featureFlagsData.teams, toFeatureFlags),
          }),
        },
      }
    )
  );

  return { flagsByTarget, refetch };
};
