import { atomWithStorage } from 'jotai/utils';

export type ExportJobType = {
  id: string;
  status: 'pending' | 'completed';
};

export const EXPORT_ATOM_KEY = 'exportsAtom';

export const exportsAtom = atomWithStorage<ExportJobType[]>(EXPORT_ATOM_KEY, []);

exportsAtom.debugLabel = EXPORT_ATOM_KEY;
