import type { GetApiProjectsProjectIdLocationsParams, LocationSchema } from '@shape-construction/api/model';
import type { NodeModel } from '@shape-construction/arch-ui/src/Tree/Tree';
import { getChildren } from '@shape-construction/arch-ui/src/Tree/tree-utils';
import { useQuery } from '@tanstack/react-query';
import UtilsArray from 'app/components/Utils/UtilsArray';
import { buildLocationTree, getLocationSiblingsIds, getParentIdByLocationId } from 'app/components/Utils/locations';
import {
  getProjectLocationsQueryOptions,
  useCreateProjectLocation,
  useDeleteProjectLocation,
  useSortProjectLocations,
  useUpdateProjectLocation,
} from 'app/queries/projects/locations';
import { useMemo } from 'react';

export function useProjectLocationsTree(
  projectId: LocationSchema['id'],
  params?: GetApiProjectsProjectIdLocationsParams,
  options?: { enabled?: boolean }
) {
  const { data, isLoading } = useQuery({
    ...getProjectLocationsQueryOptions(projectId, params),
    enabled: !!projectId && options?.enabled,
  });

  const { mutate: createProjectLocation } = useCreateProjectLocation();
  const { mutateAsync: deleteProjectLocation, data: mutateData, isPending, isSuccess } = useDeleteProjectLocation();
  const { mutate: updateProjectLocation } = useUpdateProjectLocation();
  const { mutate: sortProjectLocations } = useSortProjectLocations();

  const locationsTree = useMemo(() => (data ? buildLocationTree(data) : []), [data]);

  const deleteProjectLocationsInBatch = async (
    ids: LocationSchema['id'][],
    events: {
      onError?: () => void;
      onSuccess?: () => void;
    } = {}
  ) => {
    try {
      const mainLocation = locationsTree.find((location) => !location.data?.parentLocationId);
      const idsSortedFromBottom = [...ids].filter((location) => location !== mainLocation?.data?.id).reverse();

      await idsSortedFromBottom.reduce(async (promise, locationId) => {
        await promise;
        return deleteProjectLocation({ projectId, locationId });
      }, Promise.resolve());

      events.onSuccess?.();
    } catch {
      events.onError?.();
    }
  };

  /**
   * This methods will grab all descendents of a select node, and based on that result, it will iterate for each descendent.
   * Each descendent iteraction adds a new location with the parent id reference, and on the next iteration, the node will have the previous parent id.
   * This was made manually since our API does not support that for now.
   */
  const duplicateProjectLocation = async (
    treeData: NodeModel<LocationSchema>[],
    node: NodeModel<LocationSchema>,
    parentId: NodeModel<LocationSchema>['parent'] | null = null
  ) => {
    createProjectLocation(
      {
        projectId,
        data: {
          name: node.data?.name,
          parent_location_id: (parentId ?? node.data?.parentLocationId) as string,
          short_code: node.data?.shortCode,
        },
      },
      {
        onSuccess: (result) => {
          const children = getChildren<LocationSchema>(treeData, node.id);

          children.map((child) => duplicateProjectLocation(treeData, child, result.id));
        },
      }
    );
  };

  /**
   * It takes a locationId and moves it up in the list of locations
   * @param locationId - The id of the location you want to move
   */
  const moveUpLocation = (locationId: LocationSchema['id']) => {
    const siblingsIds = getLocationSiblingsIds(data!, locationId);
    const currentPosition = siblingsIds.indexOf(locationId);
    const nextPosition = currentPosition === 0 ? currentPosition : currentPosition - 1;
    const parentId = getParentIdByLocationId(data!, locationId)!;

    if (!parentId) return;
    if (currentPosition === nextPosition) return;

    sortProjectLocations({
      projectId,
      locationId: parentId,
      data: {
        ordered_location_ids: UtilsArray.moveElement(siblingsIds, currentPosition, nextPosition),
      },
    });
  };

  /**
   * It takes a locationId and moves it down one position in the list of its siblings
   * @param locationId - The id of the location you want to move
   */
  const moveDownLocation = (locationId: LocationSchema['id']) => {
    const siblingsIds = getLocationSiblingsIds(data!, locationId);
    const currentPosition = siblingsIds.indexOf(locationId);
    const nextPosition = currentPosition === siblingsIds.length - 1 ? currentPosition : currentPosition + 1;
    const parentId = getParentIdByLocationId(data!, locationId)!;

    if (!parentId) return;
    if (currentPosition === nextPosition) return;

    sortProjectLocations({
      projectId,
      locationId: parentId,
      data: {
        ordered_location_ids: UtilsArray.moveElement(siblingsIds, currentPosition, nextPosition),
      },
    });
  };

  return {
    isLoading,
    locationsTree,
    createProjectLocation,
    deleteProjectLocation,
    deleteProjectLocationsInBatch,
    duplicateProjectLocation,
    updateProjectLocation,
    moveUpLocation,
    moveDownLocation,
  };
}
