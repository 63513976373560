import { getGetApiFeatureFlagsQueryKey, getGetApiFeatureFlagsQueryOptions } from '@shape-construction/api/api';
import type { TeamSchema, UserSchema } from '@shape-construction/api/model';
import { useQuery } from '@tanstack/react-query';
import React, { type PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useDeviceSessionId } from './useDeviceSessionId';

type FeatureFlagContext = {
  deviceId: string;
  userId?: UserSchema['id'];
  teamId?: TeamSchema['id'];
};

const featureFlagsContext = createContext<FeatureFlagContext | undefined>(undefined);

export const useFeatureFlagContext = () => {
  const ctx = useContext(featureFlagsContext);
  if (!ctx) throw new Error('Cannot use feature flags outside FeatureFlagEntityProvider');
  return ctx;
};

type FeatureFlagContextProps = {
  deviceId?: string;
  userId?: UserSchema['id'];
  teamId?: TeamSchema['id'];
};

export const FeatureFlagEntityProvider: React.FC<PropsWithChildren<FeatureFlagContextProps>> = ({
  children,
  userId,
  teamId,
  deviceId: customDeviceId,
}) => {
  const defaultDeviceId = useDeviceSessionId();
  const deviceId = customDeviceId ?? defaultDeviceId;
  const ctxValue = useMemo(() => ({ userId, teamId, deviceId }), [userId, teamId, deviceId]);

  useWarmUpFeatureFlagsCache(ctxValue);

  return <featureFlagsContext.Provider value={ctxValue}>{children}</featureFlagsContext.Provider>;
};

const useWarmUpFeatureFlagsCache = ({ deviceId, userId }: FeatureFlagContext) => {
  useQuery(
    getGetApiFeatureFlagsQueryOptions(
      { device_session_id: deviceId },
      {
        query: {
          queryKey: [...getGetApiFeatureFlagsQueryKey({ device_session_id: deviceId }), userId],
        },
      }
    )
  );
};
