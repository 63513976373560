import classNames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export type SpinnerSize = 'sm' | 'md' | 'lg' | 'xl';
export type SpinnerColor = 'default' | 'inverse';

export interface SpinnerProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: SpinnerSize;
  color?: SpinnerColor;
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 'md', color = 'default', className, ...props }) => {
  const svgProps = {
    sm: {
      width: 16,
      height: 16,
      viewBox: '0 0 16 16',
      strokeWidth: 2,
      circleProps: { cx: 8, cy: 8, r: 7, opacity: 0.4 },
      pathD: 'M8 1 A7 7 0 0 1 15 8',
    },
    md: {
      width: 24,
      height: 24,
      viewBox: '0 0 24 24',
      strokeWidth: 3,
      circleProps: { cx: 12, cy: 12, r: 10.5, opacity: 0.4 },
      pathD: 'M12 1.5 A10.5 10.5 0 0 1 22.5 12',
    },
    lg: {
      width: 32,
      height: 32,
      viewBox: '0 0 32 32',
      strokeWidth: 4,
      circleProps: { cx: 16, cy: 16, r: 14, opacity: 0.4 },
      pathD: 'M16 2 A14 14 0 0 1 30 16',
    },
    xl: {
      width: 64,
      height: 64,
      viewBox: '0 0 64 64',
      strokeWidth: 5,
      circleProps: { cx: 32, cy: 32, r: 29.5, opacity: 0.4 },
      pathD: 'M32 2 A30 30 0 0 1 62 32',
    },
  };

  const { width, height, viewBox, strokeWidth, circleProps, pathD } = svgProps[size];

  const strokeColor = color === 'inverse' ? '#FFFFFF' : '#6366F1';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill="none"
      className={classNames(twMerge('animate-spin', className))}
      {...props}
    >
      <circle {...circleProps} />
      <path d={pathD} fill="none" />
    </svg>
  );
};
