import { Menu } from '@headlessui/react';
import classNames from 'clsx';
import React, {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  type Ref,
  forwardRef,
} from 'react';
import { twMerge } from 'tailwind-merge';

type OwnProps = PropsWithChildren<{
  className?: string;
  color?: 'default' | 'danger';
  icon?: React.FC<ComponentProps<'svg'>>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}>;
type ExcludedMenuItemProps = 'className' | 'color';

export type DropdownItemProps = Omit<ComponentPropsWithoutRef<typeof Menu.Item>, ExcludedMenuItemProps> & OwnProps;

export const DropdownItem = forwardRef<React.ElementRef<typeof Menu.Item>, DropdownItemProps>(
  (
    {
      as = 'button',
      color = 'default',
      children,
      className,
      icon: Icon,
      startAdornment,
      endAdornment,
      ...props
    }: DropdownItemProps,
    ref: Ref<HTMLElement>
  ) => {
    return (
      <Menu.Item
        as={as}
        ref={ref}
        className={twMerge(
          classNames(
            'w-full flex items-center gap-3 py-3.5 md:py-2 px-4 ui-active:bg-gray-100 ui-disabled:cursor-default ui-disabled:opacity-50',
            className
          )
        )}
        {...props}
      >
        {startAdornment}
        {Icon && (
          <Icon
            aria-label="dropdown-item-icon"
            className={classNames('h-4 w-4', {
              'text-gray-400 ui-active:text-gray-500': color === 'default',
              'text-red-400 ui-active:text-red-500': color === 'danger',
            })}
          />
        )}
        <span
          className={classNames('flex-1 text-left text-sm font-normal leading-5', {
            'text-gray-700 ui-active:text-gray-900': color === 'default',
            'text-red-700 ui-active:text-red-900': color === 'danger',
          })}
        >
          {children}
        </span>
        {endAdornment}
      </Menu.Item>
    );
  }
);

DropdownItem.displayName = 'Dropdown.Item';
