import { useMessageGetter } from '@messageformat/react';
import { exportsAtom } from './state/exportsAtom';
import useQueueToasts from './useQueueToasts';

const useExports = () => {
  const messages = useMessageGetter('exports.toasts');

  const { addToPendingJobs, addToCompletedJobs, removeFromCompletedJobs, jobQueue, initToastsFromStorage } =
    useQueueToasts('exports', exportsAtom, {
      success: messages('success'),
      loading: messages('loading'),
    });

  return {
    addToPendingExports: addToPendingJobs,
    addToCompletedExports: addToCompletedJobs,
    removeFromCompletedExports: removeFromCompletedJobs,
    exportsQueue: jobQueue,
    initToastsFromStorage,
  };
};

export default useExports;
