import { useMessage } from '@messageformat/react';
import { Button, InputAdornment, Select, type SelectRootProps } from '@shape-construction/arch-ui';
import { Calendar, type DateRange } from '@shape-construction/arch-ui/src/DatePickerCalendar';
import { useClickAway } from '@shape-construction/hooks';
import { parseDateWithFormat } from '@shape-construction/utils/DateTime';
import {
  type PredefinedOption,
  type PredefinedOptionKey,
  datePickerPredefinedOptionsMap,
  getDatePickerOptions,
} from 'app/components/Filters/DateSelect/DateSelectOptions';
import React, { forwardRef, useRef, useState } from 'react';

type FormValues = {
  relativeDate?: PredefinedOptionKey;
  date?: string;
  endDate?: string;
};
type DateSelectProps = SelectRootProps<FormValues, false>;

export const DateSelect = forwardRef<React.ElementRef<typeof Select.Root>, DateSelectProps>(
  ({ onChange, ...props }, ref) => {
    const datePickerKeys: PredefinedOptionKey[] = [
      'all',
      'today',
      'yesterday',
      'thisWeek',
      'thisMonth',
      'sevenDaysAgo',
      'thirtyDaysAgo',
      'custom',
    ];
    const datePickerOptions = getDatePickerOptions(datePickerKeys);
    const [panelOpen, setPanelOpen] = useState(false);
    const [customDateRange, setCustomDateRange] = useState<DateRange | undefined>(undefined);
    const dateLabel = useMessage('search.date.title');
    const applyLabel = useMessage('search.date.apply');
    const relativeDate = props.value?.relativeDate || 'all';
    const responsivePanelRef = useRef(null);
    const showCalendar = props.value?.relativeDate === 'custom';

    const openPanel = () => setPanelOpen(true);
    const closePanel = () => setPanelOpen(false);

    useClickAway(responsivePanelRef, () => closePanel());

    const optionToFormValues = (option: PredefinedOption) => {
      return {
        relativeDate: option.key,
        date: option.key === 'custom' ? parseDateWithFormat(option.value().from!, 'YYYY-MM-DD') : undefined,
        endDate: option.key === 'custom' ? parseDateWithFormat(option.value().to!, 'YYYY-MM-DD') : undefined,
      };
    };

    const handleOnChange = (newFormValues: FormValues) => {
      onChange?.({
        ...newFormValues,
        relativeDate: newFormValues.relativeDate === 'all' ? undefined : newFormValues.relativeDate,
      });

      if (newFormValues.relativeDate !== 'custom') closePanel();
    };

    const applyCustomDataRangeFilter = () => {
      customDateRange &&
        onChange?.({
          relativeDate: 'custom',
          date: parseDateWithFormat(customDateRange.from!, 'YYYY-MM-DD'),
          endDate: parseDateWithFormat(customDateRange.to!, 'YYYY-MM-DD'),
        });

      closePanel();
    };

    return (
      <Select.Root {...props} ref={ref} onChange={handleOnChange} by="relativeDate">
        <Select.Trigger onClick={openPanel} size="sm" startAdornment={<InputAdornment>{dateLabel}</InputAdornment>}>
          {datePickerPredefinedOptionsMap[relativeDate].label}
        </Select.Trigger>
        {panelOpen && (
          <Select.ResponsivePanel className="md:w-fit md:max-h-fit" static>
            <div className="flex flex-col" ref={responsivePanelRef}>
              <div className="flex">
                <Select.Options>
                  {datePickerOptions.map((option) => {
                    return (
                      <Select.Option key={option.key} value={optionToFormValues(option)}>
                        {option.label}
                      </Select.Option>
                    );
                  })}
                </Select.Options>
                {showCalendar && (
                  <Calendar
                    disabled={{ after: new Date() }}
                    numberOfMonths={1}
                    selected={customDateRange}
                    onSelect={(range: DateRange | undefined) => setCustomDateRange(range)}
                    mode="range"
                    className="md:flex-1"
                  />
                )}
              </div>
              {showCalendar && (
                <div className="flex flex-row-reverse p-2 border-t border-gray-300">
                  <Button color="primary" variant="text" size="xxs" onClick={applyCustomDataRangeFilter}>
                    {applyLabel}
                  </Button>
                </div>
              )}
            </div>
          </Select.ResponsivePanel>
        )}
      </Select.Root>
    );
  }
);

DateSelect.displayName = 'DateSelect';
