export const fileExtensionsReferences = {
  default: 'Default',
  /* IMAGES */
  jpg: 'Jpg',
  jpeg: 'Jpg',
  heic: 'Jpg',
  png: 'Png',
  gif: 'Gif',
  tiff: 'Tiff',
  bmp: 'Bmp',
  /* DOCUMENTS */
  doc: 'Doc',
  docx: 'Doc',
  xls: 'Xls',
  xlsx: 'Xls',
  csv: 'Csv',
  xlsm: 'Xlsm',
  xlm: 'Xlm',
  ppt: 'Ppt',
  pptx: 'Ppt',
  txt: 'Txt',
  pdf: 'Pdf',
  msg: 'Msg',
  eml: 'Eml',
  /* VIDEO */
  mp4: 'Mp4',
  mov: 'Mov',
  avi: 'Avi',
  mpg: 'Mpg',
  flv: 'Flv',
  wmv: 'Wmv',
  /* SOUND */
  mp3: 'Mp3',
  wav: 'Wav',
  wma: 'Wma',
  /* CAD */
  dgn: 'Dgn',
  dwg: 'Dwg',
  dwt: 'Dwt',
  nwd: 'Nwd',
  '3dm': 'ThreeDm',
  cnv: 'Cnv',
  skp: 'Skp',
  '3mf': 'ThreeMf',
  rvm: 'Rvm',
  /* BIM */
  ifc: 'Ifc',
  db1: 'Db1',
  dxf: 'Dxf',
  rvt: 'Rvt',
  /* PROJECT MANAGEMENT */
  mpp: 'Mpp',
  xer: 'Xer',
  asta: 'Asta',
  /* OTHER FILES EXTENSIONS */
  svg: 'Svg',
  zip: 'Zip',
  rar: 'Zip',
  gzip: 'Zip',
  psd: 'Psd',
} as const;
