import { cn } from '@shape-construction/arch-ui';
import Spinner from '@shape-construction/arch-ui/src/Spinner';
import React from 'react';

export interface LoadingSpinnerProps {
  variant?: 'screen';
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ variant }) => {
  return (
    <div
      className={cn('flex items-center justify-center w-full', { 'fixed inset-0': variant === 'screen' })}
      data-testid="loading-spinner"
    >
      <Spinner size="lg" />
    </div>
  );
};
