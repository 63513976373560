import { dismissToast, showInfoToast, showSuccessToast } from '@shape-construction/arch-ui/src/Toast/toasts';
import { type Draft, produce } from 'immer';
import { type SetStateAction, type WritableAtom, useAtom } from 'jotai';
import type { RESET } from 'jotai/utils';

const useQueueToasts = <T extends { id: string; status: 'pending' | 'completed' }>(
  queueName: string,
  jobQueueAtom: WritableAtom<T[], typeof RESET | SetStateAction<T[]>>,
  messages: {
    loading: string;
    success: string;
  }
) => {
  const [jobQueue, setJobQueue] = useAtom(jobQueueAtom);

  const showJobCompletedToast = (jobId: string) =>
    showSuccessToast({
      message: messages.success,
      id: jobId,
      duration: Number.POSITIVE_INFINITY,
      onClose: () => removeFromCompletedJobs(jobId),
    });

  const showPendingJobToast = (jobId: string) =>
    showInfoToast({
      message: messages.loading,
      id: jobId,
      duration: Number.POSITIVE_INFINITY,
      loading: true,
    });

  const addToPendingJobs = (job: T) => {
    setJobQueue((prev) => [...prev, { ...job, status: 'pending' }]);
    showPendingJobToast(job.id);
  };

  const findJobById = (draft: Draft<T>[], jobId: string): Draft<T> | undefined => {
    return draft.find((item: Draft<T>) => item.id === jobId);
  };

  const markJobAsCompleted = (item: Draft<T>): void => {
    item.status = 'completed';
  };

  const addToCompletedJobs = (job: T) => {
    setJobQueue((prev: T[]) => {
      const nextState = produce(prev, (draft) => {
        const item = findJobById(draft, job.id);
        if (item) {
          markJobAsCompleted(item);
        }
      });

      return nextState;
    });
    showJobCompletedToast(job.id);
  };

  const removeFromCompletedJobs = (jobId: string) => {
    setJobQueue((prev) => {
      const updatedJobs = prev.filter((job) => job.id !== jobId);
      dismissToast(jobId);
      return updatedJobs;
    });
  };

  const initToastsFromStorage = () => {
    jobQueue?.forEach((job) => {
      if (job.status === 'pending') {
        showPendingJobToast(job.id);
      } else if (job.status === 'completed') {
        showJobCompletedToast(job.id);
      }
    });
  };

  return {
    jobQueue,
    addToPendingJobs,
    addToCompletedJobs,
    removeFromCompletedJobs,
    initToastsFromStorage,
  };
};

export default useQueueToasts;
