import type { ConstructionRoleListSchema } from '@shape-construction/api/model';
import type { ConstructionRoleName, ConstructionRoles } from './types';

export const transformConstructionRoles = (roles: ConstructionRoleListSchema) => {
  return roles
    .sort((a, b) => a.sortPosition - b.sortPosition)
    .reduce<ConstructionRoles>((acc, role) => {
      const roleCode = role.value as ConstructionRoleName;
      return {
        ...acc,
        [roleCode]: {
          value: roleCode,
          label: role.label,
        },
      };
    }, {} as ConstructionRoles);
};
