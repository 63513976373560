import type React from 'react';

type NotificationTitleProps = {
  actor: string;
  action: string;
  object?: string;
};

export const NotificationDecoratorTitle = ({ actor, action, object }: NotificationTitleProps) => {
  return (
    <>
      <span className="font-semibold"> {actor} </span> {action}{' '}
      {object && <span className="font-semibold"> {object} </span>}
    </>
  );
};
