import type { NotificationSchema, NotificationTypeSchema, PushNotificationSchema } from '@shape-construction/api/model';
import {
  ArrowUturnLeftIcon,
  AtSymbolIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChatPrivateIcon,
  CheckCircleIcon,
  ClockIcon,
  FolderIcon,
  NoSymbolIcon,
  UserCircleIcon,
} from '@shape-construction/arch-ui/src/Icons/solid';
import type { NotificationIconColorType } from '@shape-construction/arch-ui/src/NotificationCard/NotificationIcon';
import type { ShiftReportCommsChannel } from 'app/queries/shiftReports/comments/comments';
import type React from 'react';

type CommonNotificationDecorationFields = {
  body?: string;
  bodyExtra?: string;
  icon?: string | React.FC<React.SVGProps<SVGSVGElement>> | null;
  title: JSX.Element | string;
  url: string;
};

export type NotificationDecorationFields = CommonNotificationDecorationFields & {
  color?: NotificationIconColorType;
  batchTitle?: JSX.Element | string;
};

export type PushNotificationDecorationFields = CommonNotificationDecorationFields & {
  tag?: string;
};
export type InAppNotificationDecoratedType = NotificationSchema & NotificationDecorationFields;

export type PushNotificationDecoratedType = PushNotificationSchema & PushNotificationDecorationFields;

export const NOTIFICATION_ICON_TYPES: Record<NotificationTypeSchema, React.FC<React.SVGProps<SVGSVGElement>>> = {
  added_to_project: FolderIcon,
  you_were_assigned_to_issue: UserCircleIcon,
  your_issue_assignment_was_accepted: UserCircleIcon,
  your_issue_assignment_was_rejected: UserCircleIcon,
  your_issue_was_reassigned: UserCircleIcon,
  new_issue_comment: ChatBubbleOvalLeftEllipsisIcon,
  new_issue_private_comment: ChatPrivateIcon,
  new_shift_report_comment: ChatBubbleOvalLeftEllipsisIcon,
  issue_comment_mention: AtSymbolIcon,
  shift_report_comment_mention: AtSymbolIcon,
  issue_needs_your_approval: ClockIcon,
  your_issue_approval_request_was_rejected: NoSymbolIcon,
  your_issue_approval_request_was_accepted: CheckCircleIcon,
  issue_was_resolved: CheckCircleIcon,
  issue_was_reopened: ArrowUturnLeftIcon,
};

export const NOTIFICATION_ICON_COLORS: Record<NotificationTypeSchema, NotificationIconColorType> = {
  added_to_project: 'indigo',
  you_were_assigned_to_issue: 'indigo',
  your_issue_assignment_was_accepted: 'green',
  your_issue_assignment_was_rejected: 'red',
  your_issue_was_reassigned: 'indigo',
  new_issue_comment: 'yellow',
  new_issue_private_comment: 'yellow',
  new_shift_report_comment: 'yellow',
  issue_comment_mention: 'pink',
  shift_report_comment_mention: 'pink',
  issue_needs_your_approval: 'indigo',
  your_issue_approval_request_was_rejected: 'red',
  your_issue_approval_request_was_accepted: 'green',
  issue_was_resolved: 'green',
  issue_was_reopened: 'indigo',
};

export const buildNotificationsIssueUrl = (projectId: string, issueId: string) =>
  `/projects/${projectId}/issues/${issueId}`;
export const buildShiftReportCommentsUrl = (
  projectId: string,
  shiftReportId: string,
  channel: ShiftReportCommsChannel
) => `/projects/${projectId}/shift-reports/${shiftReportId}/${channel}`;
export const buildProjectUrl = (projectId: string) => `/projects/${projectId}`;
