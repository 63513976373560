import React from 'react';
import { cn } from '../utils/classes';

import { Avatar } from '../Avatar';
import { DoubleCircleIcon } from '../Icons/custom';
import { NotificationIcon, type NotificationIconColorType } from './NotificationIcon';

export interface NotificationCardProps {
  actorName?: string | null;
  avatarUrl?: string | null;
  body?: string;
  bodyExtra?: string;
  color?: NotificationIconColorType;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  handleClick?: () => void;
  notificationId: string;
  read: boolean;
  subtitle?: React.ReactNode;
  title?: JSX.Element | string;
  bodyClassNames?: string;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  avatarUrl,
  actorName,
  body,
  bodyClassNames,
  bodyExtra,
  color = 'indigo',
  icon,
  handleClick,
  notificationId,
  read,
  subtitle,
  title,
}) => {
  return (
    <button
      type="button"
      aria-label="notification"
      aria-pressed={read}
      className={cn('w-full cursor-pointer text-left border-b border-neutral-subtlest', {
        'inherit hover:bg-neutral-white-hovered': read,
        'bg-brand-subtlest hover:bg-brand-subtlest-hovered': !read,
      })}
      onClick={handleClick}
      data-testid={`res-notification-card-${notificationId}`}
    >
      <div className="flex flex-row p-4 items-start">
        <div className="mr-4">
          <div className="relative">
            <Avatar
              aria-label="notifcation-avatar"
              size="md"
              imgURL={avatarUrl || ''}
              text={actorName?.charAt(0) || ''}
              variant="circle"
            />
            <div className="absolute right-[-4px] bottom-[-4px]">
              <NotificationIcon color={color} icon={icon} />
            </div>
          </div>
        </div>
        <div className="flex-auto">
          <span className="m-0 block">{title}</span>
          <div className="m-0 text-gray-500 font-normal flex flex-col">
            {body && (
              <p
                data-testid="notification-body"
                className={cn('line-clamp-3 text-sm leading-5 font-normal', bodyClassNames)}
              >
                {body}
              </p>
            )}

            {bodyExtra && (
              <div className="flex flex-row gap-2" data-testid="notification-body-extra">
                <div className="rounded-full bg-neutral-subtle-hovered w-1 shrink-0" />
                <p className="text-sm leading-5 font-normal text-neutral-bold line-clamp-3 flex-1 break-all">
                  {bodyExtra}
                </p>
              </div>
            )}
            {subtitle}
          </div>
        </div>
        {read !== true && <DoubleCircleIcon className="h-6 w-6 m-0 mx-auto text-icon-brand shrink-0" />}
      </div>
    </button>
  );
};

export default NotificationCard;
