import type { NotificationSchema } from '@shape-construction/api/model';
import {
  type InAppNotificationDecoratedType,
  NOTIFICATION_ICON_COLORS,
  NOTIFICATION_ICON_TYPES,
  type NotificationDecorationFields,
  buildNotificationsIssueUrl,
  buildProjectUrl,
  buildShiftReportCommentsUrl,
} from 'app/constants/NotificationDecorations';
import type React from 'react';
import { NotificationDecoratorTitle } from './NotificationDecoratorTitle';

const buildNotificationDecorationFields = (
  notification: NotificationSchema
): NotificationDecorationFields | undefined => {
  const { firstName: actorFirstName = '', name: actorName = '' } = notification.actor;
  const {
    projectTitle = '',
    issueTitle = '',
    projectId,
    issueId,
    message,
    shiftReportTitle,
    shiftReportDate,
    shiftReportId,
    channel,
    progress,
    reason,
  } = notification.params as any;

  switch (notification.type) {
    case 'added_to_project':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="has added you to the project"
            object={projectTitle}
          />
        ),
        body: 'Click here to view the project and start collaborating.',
        icon: NOTIFICATION_ICON_TYPES.added_to_project,
        color: NOTIFICATION_ICON_COLORS.added_to_project,
        url: buildProjectUrl(projectId),
      };
    case 'you_were_assigned_to_issue':
      return {
        title: (
          <NotificationDecoratorTitle actor={actorFirstName} action="has assigned you the issue" object={issueTitle} />
        ),
        icon: NOTIFICATION_ICON_TYPES.you_were_assigned_to_issue,
        color: NOTIFICATION_ICON_COLORS.you_were_assigned_to_issue,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'your_issue_assignment_was_accepted':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="has accepted an issue you assigned"
            object={issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_accepted,
        color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_accepted,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'your_issue_assignment_was_rejected':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="has rejected an issue you assigned"
            object={issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_assignment_was_rejected,
        color: NOTIFICATION_ICON_COLORS.your_issue_assignment_was_rejected,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'your_issue_was_reassigned':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="re-assigned an issue you created assigned"
            object={issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_was_reassigned,
        color: NOTIFICATION_ICON_COLORS.your_issue_was_reassigned,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'new_issue_comment':
      return {
        title: (
          <NotificationDecoratorTitle actor={actorFirstName} action="left a comment on the issue" object={issueTitle} />
        ),
        bodyExtra: `"${message}"`,
        batchTitle: (
          <NotificationDecoratorTitle actor={actorName} action="left the latest comment on" object={issueTitle} />
        ),
        icon: NOTIFICATION_ICON_TYPES.new_issue_comment,
        color: NOTIFICATION_ICON_COLORS.new_issue_comment,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'new_issue_private_comment':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="left a private comment on the issue"
            object={issueTitle}
          />
        ),
        bodyExtra: `"${message}"`,
        batchTitle: (
          <NotificationDecoratorTitle
            actor={actorName}
            action="left the latest private comment on"
            object={issueTitle}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.new_issue_private_comment,
        color: NOTIFICATION_ICON_COLORS.new_issue_private_comment,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'new_shift_report_comment':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="left a comment on the shift report"
            object={shiftReportTitle || shiftReportDate || ''}
          />
        ),
        bodyExtra: `"${message}"`,
        batchTitle: (
          <NotificationDecoratorTitle
            actor={actorName}
            action="left the latest comment on"
            object={shiftReportTitle || shiftReportDate || ''}
          />
        ),
        icon: NOTIFICATION_ICON_TYPES.new_shift_report_comment,
        color: NOTIFICATION_ICON_COLORS.new_shift_report_comment,
        url: buildShiftReportCommentsUrl(projectId, shiftReportId, channel),
      };
    case 'issue_comment_mention':
      return {
        title: (
          <NotificationDecoratorTitle actor={actorFirstName} action="mentioned you on the issue" object={issueTitle} />
        ),
        // TODO: API is not sending the message
        // bodyExtra: message && `"${message}"`,
        icon: NOTIFICATION_ICON_TYPES.issue_comment_mention,
        color: NOTIFICATION_ICON_COLORS.issue_comment_mention,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'shift_report_comment_mention':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="mentioned you on the shift report"
            object={shiftReportTitle || shiftReportDate || ''}
          />
        ),
        // TODO: API is not sending the message
        // bodyExtra: message && `"${message}"`,
        icon: NOTIFICATION_ICON_TYPES.shift_report_comment_mention,
        color: NOTIFICATION_ICON_COLORS.shift_report_comment_mention,
        url: buildShiftReportCommentsUrl(projectId, shiftReportId, channel),
      };
    case 'issue_needs_your_approval':
      return {
        title: (
          <>
            The issue <span className="font-semibold">{issueTitle}</span> needs your approval
          </>
        ),
        icon: NOTIFICATION_ICON_TYPES.issue_needs_your_approval,
        color: NOTIFICATION_ICON_COLORS.issue_needs_your_approval,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'your_issue_approval_request_was_rejected':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action="rejected your approval request for the issue"
            object={issueTitle}
          />
        ),
        bodyExtra: `Reason: "${reason.trim()}"`,
        icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_rejected,
        color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_rejected,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'your_issue_approval_request_was_accepted':
      return {
        title: (
          <>
            <span className="font-semibold"> {actorFirstName} </span> {`(Approver ${progress})`} approved the issue{' '}
            <span className="font-semibold"> {issueTitle}</span>
          </>
        ),
        icon: NOTIFICATION_ICON_TYPES.your_issue_approval_request_was_accepted,
        color: NOTIFICATION_ICON_COLORS.your_issue_approval_request_was_accepted,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'issue_was_resolved':
      return {
        title: (
          <NotificationDecoratorTitle
            actor={actorFirstName}
            action={`(Approver ${progress}) approved the issue`}
            object={issueTitle}
          />
        ),
        body: 'Issue resolved!',
        icon: NOTIFICATION_ICON_TYPES.issue_was_resolved,
        color: NOTIFICATION_ICON_COLORS.issue_was_resolved,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
    case 'issue_was_reopened':
      return {
        title: <NotificationDecoratorTitle actor={actorFirstName} action="reopened the issue" object={issueTitle} />,
        icon: NOTIFICATION_ICON_TYPES.issue_was_reopened,
        color: NOTIFICATION_ICON_COLORS.issue_was_reopened,
        url: buildNotificationsIssueUrl(projectId, issueId),
      };
  }
};

export const NotificationDecorator = (notification: NotificationSchema): InAppNotificationDecoratedType | undefined => {
  const decoratedFields = buildNotificationDecorationFields(notification);
  if (!decoratedFields) return undefined;
  return { ...notification, ...decoratedFields };
};
